<template>
  <div class="article">
    <div class="schedule_options">
      <div class="head">
        <div class="input_search">
          <input
            type="text"
            :class="{ active: targetUser !== null }"
            :placeholder="targetUser == null ? '사용자 조회' : targetUser.name"
            disabled
          />
          <button @click="openModal()">
            <i class="fa fa-search"></i>
          </button>
        </div>
        <h5 v-if="targetUser">항목선택</h5>
      </div>
      <div class="option" v-if="targetUser">
        <div
          class="input_checkbox"
          v-for="(option, index) in manageCalendarOptions"
          :key="option.id"
        >
          <label
            for="checkbox1"
            class="label_txt"
            @click="setCheckedBox(option.setting, index)"
            >{{ option.name }}</label
          >
          <label
            for="checkbox1"
            class="chk_box"
            :class="{ active: option.setting }"
            @click="setCheckedBox(option.setting, index)"
          >
            <i class="fa fa-check"></i>
          </label>
          <input type="checkbox" id="checkbox1" />
        </div>
      </div>
      <div class="btn_wrap" v-if="isPermissionOn" v-show="targetUser">
        <button
          class="btn_sub2"
          @click="submit"
          :disabled="targetUser == null || isValidModify"
        >
          저장
        </button>
      </div>
      <user-modal
        v-if="isUserModalOpen"
        :users="users"
        @onclose="isUserModalOpen = false"
        @onselect="
          $event => {
            targetedUser($event);
            isUserModalOpen = false;
          }
        "
      ></user-modal>
      <div class="modal_layer" v-if="isUserModalOpen" @click="closeModal"></div>
    </div>
    <calendar-manage
      :key="rendering"
      :calendarOptions="manageCalendarOptions"
    ></calendar-manage>
  </div>
</template>

<script>
import CalendarManage from '@/layouts/components/CalendarManage';
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import MODAL_MIXIN from '@/mixins/modal';
import FAVORITE_MIXIN from '@/mixins/favorite';
import UserModal from '@/layouts/components/UserModal';

export default {
  mixins: [FETCH_MIXIN, MODAL_MIXIN, FAVORITE_MIXIN],
  components: {
    CalendarManage,
    UserModal,
  },
  data() {
    return {
      isUserModalOpen: false,
      showCalendar: false,
      rendering: 0,
      title_date: 0,
    };
  },
  computed: {
    ...mapGetters({
      calendarOptions: 'getCalendarOptionsFromCalendar',
      manageCalendarOptions: 'getmanageCalendarOptionsFromMainCalendar',
      calendarOptionsMain: 'getCalendarOptionsFromMainCalendar',
      targetUser: 'getTargetUserFromCalendar',
      users: 'getUsersFromUserPage',
      userId: 'getUserId',
      user_role_type: 'getUserRoleTypeFromUserPage',
      month: 'getMonthFromCalendar',
      year: 'getYearFromCalendar',
    }),
    isValidModify() {
      if (this.targetUser != null) {
        const modifyData = JSON.stringify(this.manageCalendarOptions);
        const originData = JSON.stringify(this.calendarOptions);
        if (originData == modifyData) {
          return true;
        } else return false;
      } else return true;
    },
  },
  methods: {
    async fetchUserList() {
      try {
        this.showSpinner();
        await this.$store
          .dispatch('FETCH_USER_LIST')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
    openModal() {
      this.isUserModalOpen = true;
      this.rendering--;
    },
    closeModal() {
      this.isUserModalOpen = false;
    },
    async targetedUser(arg) {
      await this.$store.commit('setTargetUserToCalendar', arg);
      await this.FETCH_WITH_PAYLOAD('FETCH_CALENDAR', arg.id, '사용자');
      // await this.FETCH_WITH_PAYLOAD('FETCH_MAIN_CALENDAR', arg.id, '사용자');
      await this.$store.commit(
        'setmanageCalendarOptionsFromMainCalendar',
        this.lodash.clonedeep(this.calendarOptions),
      );
      this.rendering--;
    },
    setCheckedBox(value, index) {
      if (this.calendarOptions !== undefined) {
        if (value) {
          this.manageCalendarOptions[index].setting = false;
          if (this.targetUser.account == this.userId) {
            this.calendarOptionsMain[index].setting = false;
          }
        } else {
          this.manageCalendarOptions[index].setting = true;
          if (this.targetUser.account == this.userId) {
            this.calendarOptionsMain[index].setting = true;
          }
        }
        this.rendering++;
      }
    },
    async submit() {
      let data = this.lodash.clonedeep(this.manageCalendarOptions);
      this.showSpinner();
      await this.$store
        .dispatch('INSERT_CALENDAR', {
          user_id: this.targetUser.id,
          data_list: data,
        })
        .then(() => {
          this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
          this.FETCH_WITH_PAYLOAD(
            'FETCH_CALENDAR',
            this.targetUser.id,
            '일정 관리 정보',
          );
          // this.FETCH_WITH_PAYLOAD(
          //   'FETCH_MAIN_CALENDAR',
          //   this.targetUser.id,
          //   '사용자',
          // );
          this.rendering++;
        })
        .catch(error => {
          this.openOneButtonModal('저장 중 오류', '주요 일정 저장중 오류');
          console.log(error);
        })
        .finally(() => {
          this.hideSpinner();
        });
      if (this.targetUser.account == this.userId) {
        await this.$store
          .dispatch('INSERT_MAIN_CALENDAR', {
            user_id: this.targetUser.id,
            data_list: data,
          })
          .then(() => {
            this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
            this.FETCH_WITH_PAYLOAD(
              'FETCH_CALENDAR',
              this.targetUser.id,
              '일정 관리 정보',
            );
            console.log('test log');
            this.FETCH_WITH_PAYLOAD(
              'FETCH_MAIN_CALENDAR',
              this.targetUser.id,
              '사용자',
            );
            this.rendering++;
          })
          .catch(error => {
            this.openOneButtonModal('저장 중 오류', '주요 일정 저장중 오류');
            console.log(error);
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
  },
  async created() {
    if (this.users.length == 0) {
      await this.fetchUserList();
    }
    if (this.calendarOptions.length == 0) {
      const findId = this.users.find(x => x.account == this.userId).id;
      await this.FETCH_WITH_PAYLOAD('FETCH_CALENDAR', findId, '일정 관리 정보');
    }

    await this.$store.commit(
      'setmanageCalendarOptionsFromMainCalendar',
      this.lodash.clonedeep(this.calendarOptions),
    );
  },
};
</script>

<style lang="scss" scoped></style>
