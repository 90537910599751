<template>
  <div id="contents" class="schedule_management">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <button class="btn_admin" v-if="isPermissionOn">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li class="active">
            <a href="javascript: void(0)">관리</a>
          </li>
        </ul>
      </div>
      <calendar-form></calendar-form>
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import CalendarForm from '@/views/forms/System/CalendarForm';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    CalendarForm,
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitCalendarPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
